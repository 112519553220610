<template>
  <v-data-table
    :headers="computedHeaders"
    :items="data"
    :loading="tableLoading"
    class="elevation-1"
  >
    <template v-slot:item.actions="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" small class="mr-2" @click="edit(item)">
            mdi-pencil
          </v-icon>
        </template>
        <span>Editar</span>
      </v-tooltip>
    </template>
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Metas PDTIC Anterior</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="600px" @click:outside="close">
          <v-card>
            <v-card-title>
              <span class="headline">Meta PDTIC Anterior</span>
            </v-card-title>
            <ValidationObserver ref="form" v-slot="{ passes }">
              <v-form @submit.prevent="passes(save)" width="100%">
                <v-card-text>
                  <v-container>
                    <ValidationProvider
                      v-if="$state.role != 'eqpdtiuo'"
                      vid="uo"
                      name="Unidade Organizacional"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <UOAutoComplete
                        v-model="Item.uo"
                        :error-messages="errors"
                        readonly
                      />
                    </ValidationProvider>
                    <ValidationProvider
                      vid="necessidade_nome"
                      name="Necessidade"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-text-field
                        v-model="Item.necessidade_nome"
                        label="Necessidade"
                        :error-messages="errors"
                        readonly
                      ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider
                      vid="nome"
                      name="Nome"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-text-field
                        v-model="Item.nome"
                        label="Nome"
                        :error-messages="errors"
                        readonly
                      ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider
                      vid="descricao"
                      name="Descrição"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-textarea
                        v-model="Item.descricao"
                        label="Descrição"
                        :error-messages="errors"
                        readonly
                      ></v-textarea>
                    </ValidationProvider>
                    <ValidationProvider
                      vid="is_concluida"
                      name="Concluída?"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-checkbox
                        v-model="Item.is_concluida"
                        label="Concluída?"
                        :error-messages="errors"
                      ></v-checkbox>
                    </ValidationProvider>
                    <ValidationProvider
                      vid="justificativa_conclusao"
                      name="Justificativa da Conclusão"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-textarea
                        v-model="Item.justificativa_conclusao"
                        label="Justificativa da Conclusão"
                        :error-messages="errors"
                      ></v-textarea>
                    </ValidationProvider>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="darken-1" text @click="close">Cancelar</v-btn>
                  <v-btn :loading="saving" color="darken-1" text @click="save"
                    >Salvar</v-btn
                  >
                </v-card-actions>
              </v-form>
            </ValidationObserver>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
  </v-data-table>
</template>

<script>
import { ResultadosMetas } from "@/api/eqpdti";
import UOAutoComplete from "@/components/UOAutoComplete";
export default {
  name: "ResultadosMetas",
  components: {
    UOAutoComplete,
  },
  data() {
    return {
      dialog: false,

      saving: false,
      tableLoading: false,
      errors: [],
      search: null,
      uos: [],
      uoList: [],
      data: [],
      Item: {},
      headers: [
        {
          text: "ID",
          value: "meta_id",
          align: "center",
        },
        {
          text: "Nome",
          value: "nome",
          align: "left",
        },
        {
          text: "Necessidade",
          value: "necessidade_nome",
          align: "left",
        },
        {
          text: "Foi concluída?",
          value: "concluida",
          align: "center",
        },
        {
          text: "Ações",
          value: "actions",
          sortable: false,
          align: "end",
        },
      ],
    };
  },
  computed: {
    computedHeaders() {
      this.fetch();
      if (this.$state.role != "eqpdtiuo") {
        return [
          {
            text: "Unidade Organizacional",
            value: "uo_sigla",
            align: "center",
          },
        ].concat(this.headers);
      }
      return this.headers;
    },
  },
  methods: {
    edit(item) {
      this.Item = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$refs.form.reset();
      this.$nextTick(() => {
        this.Item = {};
      });
    },
    save() {
      this.$refs.form.validate().then((valid) => {
        if (valid) {
          this.saving = true;
          const _then = () => {
            this.saving = false;
            this.close();
            this.fetch();
          };
          const _catch = (error) => {
            this.saving = false;
            this.errors = error.response.data;
            if (this.errors["non_field_errors"]) {
              this.$emitter.emit(
                "alert:error",
                this.errors["non_field_errors"][0]
              );
            } else {
              this.$emitter.emit("alert:error", this.errors);
            }
          };
          if (this.Item["id"]) {
            let id = this.Item["id"];

            ResultadosMetas.update(id, this.Item).then(_then).catch(_catch);
          }
        }
      });
    },
    fetch() {
      this.tableLoading = true;
      ResultadosMetas.list({}).then((response) => {
        this.data = response.data.map((item) => {
          item.concluida =
            item.is_concluida === true
              ? "Sim"
              : "Não";
          return item;
        });
        this.tableLoading = false;
      });
    },
  },
  // created() {
  //   this.fetch();
  // },
};
</script>
